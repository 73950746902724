.team-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.team-list {
  padding: 30px 0;
  padding-bottom: 70px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 50px;
  width: 60%;
  justify-content: center;
  align-items: center;
}

.member-container {
  margin: auto;
  display: flex;
  width: 150px;
  padding: 13px 11px 16px;
  gap: 30px;
}

.profile-container {
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 100px;
}

.profile-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.image-name-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.member-name {
   font-weight: 500;
}

.member-title {
  color: var(--main-blue);
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  margin: auto 0 0;
}

a.nostyle:link {
  text-decoration: inherit;
  color: inherit;
}

a.nostyle:visited {
  text-decoration: inherit;
  color: inherit;
}

@media screen and (max-width: 600px) {
  .team-list {
    width: 80%;
    column-gap: 10px;
    row-gap: 10px;
  }
}

@media screen and (max-width: 460px) {

  .member-container {
    width: 130px;
  }

  .profile-container {
    width: 100px;
    height: 100px;
  }

  .member-title {
    font-size: 14px;
    margin: auto 0 0;
  }
}

@media screen and (max-width: 360px) {

  .team-list {
    width: 90%;
    column-gap: 10px;
    row-gap: 10px;
  }

  .profile-container {
    width: 80px;
    height: 80px;
  }

}