.button {
  background: linear-gradient(259deg, #AD006E 0%, #C679CA 85.29%);
  box-shadow: -8px 4px 24px 0px rgba(48, 0, 43, 0.43),
              -10.91px 2.12px 11.11px 0px rgba(218, 150, 249, 1) inset,
              10.91px -2.12px 11.11px 0px rgba(173, 0, 110, 1) inset;
  border-radius: 20px;
  text-shadow: 0 0 5px #00000055;
  cursor: pointer;
  display: block;
  padding: 12px 35px;
  /*padding: 15px 35px 12px 35px;*/
  width: fit-content;
  font-family: "Maven Pro", monospace;
  font-weight: 800;
}

.button:hover {
  background-color: var(--ctext-color);
}

.button:hover > .text {
  color: black;
}

.container > div.button {
  margin: 10px 0;
}