@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');

@font-face {
    font-family: 'Mersad';
    src: url("./fonts/Mersad Thin.woff") format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Mersad';
    src: url("./fonts/Mersad ExtraLight.woff") format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Mersad';
    src: url("./fonts/Mersad Light.woff") format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Mersad';
    src: url("./fonts/Mersad Regular.woff") format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Mersad';
    src: url("./fonts/Mersad Medium.woff") format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Mersad';
    src: url("./fonts/Mersad SemiBold.woff") format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Mersad';
    src: url("./fonts/Mersad Bold.woff") format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Mersad';
    src: url("./fonts/Mersad ExtraBold.woff") format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Mersad';
    src: url("./fonts/Mersad Black.woff") format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Graftyne Display';
    src: url("./fonts/Neue Graftyne-VF.ttf") format('truetype');
    font-weight: bold;
    font-style: normal;
}