.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.container > * {
  margin: 40px 0;
}

.foreground {
  width: var(--main-area);
}

.intro {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
}

.intro-logo {
  font-size: var(--intro-logo-size);
  text-transform: uppercase;
  width: 100%;
}

.intro-logo > img {
  max-height: 500px;
}

.intro-text > * {
  color: var(--text-color);
  font-size: var(--base-text-size);
  font-weight: 400;
  max-width: 100%;
  text-align: center;
  margin: 40px 0;
}

.intro .button {
  transition: ease-in-out 0.1s;
}
